.timeline {
  width: 800px; // can be 100% or whatever, 800px just for demo
  color: #ffffff;
  // position: absolute;
  z-index: 78;
  bottom: 30px; /* 30px from footer */
  right: 20px;
  // padding: 16px 16px 32px;
  &__wrapper {
    margin-top: 6px;
    width: 100%;
  }
  &__milestone {
    &-group {
      position: relative;
      margin-left: -.5rem;
      margin-right: -1.75rem;
    }
    &-item {
      font-weight: bold;
      font-size: 25px;
      cursor: pointer;
      position: relative;
    }
  }
  &__control {
    .btn-icon {
      background: #6DCC9D;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.collapsed .timeline {
    &__wrapper {
      width: 0;
      overflow: hidden;
    }
    &__control {
      i {
        transform: rotate(180deg);
      }
    }
  }
}
