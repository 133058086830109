.modal-backdrop.fade {
  opacity: 0 !important;
}

.wrap-user-type-modal {
  .modal-content {
    border-radius: 0px;
  }

  .wrap-modal-body {
    padding: 2rem;

    .modal-form__title {
      font-size: 20px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
  }

  .btn-outline {
    border: 1px solid #000000;
    border-radius: 0px;
    padding: 16px 54px;
    background-color: #ffffff;
  }
}

.wrap-list-user-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      border-radius: 0px;
    }
  }

  .wrap-modal-body {
    padding: 2rem;

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-group {
      border-bottom: 1px solid #414345;
      width: 100%;

      span {
        padding-top: 16px;
      }
    }

    .form-control {
      border-radius: 0;
      border: 0;
      height: 40px;
      padding: 0px;
      line-height: 2;
      background: #fff;
      font-size: 14px;
      padding-top: 1rem;
      color: #414345;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .wrap-user-list {
    border: 1px solid #000;
    padding: 8px 16px 8px 6px;
    overflow-y: scroll;
    height: 200px;

    p {
      padding-left: 10px;
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 600;

      &:active {
        background-color: #000;
        color: #eeeeee;
      }

      &:focus {
        background-color: #000;
        color: #eeeeee;
      }
    }
  }

  .wrap-button {
    margin-top: 50px;

    button {
      background: #fff;
      color: #000;
      padding: 16px;
      font-weight: bold;
      border: 1px solid #000;
      font-size: 14px;
      width: 170px;
      text-transform: uppercase;
    }
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    border-left: 12px solid #eeeeee;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-left: 12px solid #D5D5D5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
}

.wrap-create-user-profile-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      border-radius: 0px;
    }
  }

  .wrap-modal-body {
    padding: 2rem;

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-group {
      border-bottom: 1px solid #414345;
      width: 100%;

      span {
        padding-top: 16px;
      }
    }

    .form-control {
      border-radius: 0;
      border: 0;
      height: 40px;
      padding: 0px;
      line-height: 2;
      background: #fff;
      font-size: 14px;
      padding-top: 1rem;
      color: #414345;

      &:focus {
        box-shadow: none;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.wrap-end-guide-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 90;
  background-color: #000;
  opacity: 1;

  .modal-end-guide {
    background-color: #fff !important;
    position: absolute;
    top: 120px;
    left: 60px;
    width: calc(100% - 120px);
    height: calc(100% - 240px);

    .modal-end-guide-body {
      height: 80%;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
        margin-right: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #eeeeee;
      }

      &::-webkit-scrollbar-thumb {
        background: #d4d4d4;
        min-height: 40px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;

      border: unset;
      background-color: unset;
      // color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      padding: 16px;
      line-height: 15px;
      font-weight: 300;

      &:hover {
        background-color: #fff;
      }
    }
  }
}
