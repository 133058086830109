.card-side.bg-white {
  color: #000;
}

.container-absolute {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 0;right: 0;
  height: calc(100vh - 200px); /* header 100px; footer 100px */
  // font-family: 'Roboto', sans-serif;
  @media only screen and (max-width: 1180px) {
    height: calc(90vh - 200px);
  }
  z-index: 80;

  .wrapper-modal{
    height:100%;
    @media only screen and (max-width: 1180px) {
      height: 90%
    }
    @media only screen and (max-width: 1366px) {
      height: calc(90%);
    }
  }

  .card {
    height: 100%;
    &-body {
      height: 100%;
      overflow: auto;
    }
  }

  .card-header, .card-footer {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    background: #fff;
    border: 0;
  }
  .card-title {
    // font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    position: relative;
    // &.underline-sm {
    //   display: inline-block;
    //   &::before {
    //     content:'';
    //     display: block;
    //     width: 30%;
    //     height: 3px;
    //     background: #000;
    //     position: absolute;
    //     left: 0;
    //     bottom: -18px;
    //   }
    // }
  }
  .list-group {
    &-title {
      font-weight: 700;
      color: #000;
    }
    &-item {
      &:not(.list-group-title):not(.active):hover {
        background: #f2f2f2;
      }
      &.active {
        color: #fff;
        background: #000;
      }
    }
  }
  th {
    font-weight: 700;
  }
  th, td {
    white-space: nowrap;
  }
  tr {
    cursor: pointer;
  }
  tr.active {
    background: #000;
    color: #fff;
    &:hover {
      background: #000;
      color: #fff;
    }
  }

  .btn {
    padding: 14px 16px 12px;
    border-radius: 0;
    border: 1px solid #000;
    box-shadow: none;
    width: 170px;
    font-size: 13px;
    &-solid {
      background-color: #000;
      color: #fff;
      &:hover, &:focus {
        background-color: #333;
      }
    }
    &-outline {
      background: #fff;
      &:hover, &:focus {
        background: #f2f2f2;
      }
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}


#precint-filter {
  width: 200px;
  .card-header {
    height: 120px;
    overflow: hidden;
  }
}
#precint-info {
  width: 285px;
  .card-header {
    position: relative;
  }
}

.table-precinct-bordered {
  &.table > :not(:last-child) > :last-child > * {
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
  }
  &.table > :not(caption) > * > * {
    border-bottom-width: 0px;
    border-width: 0px 2px;
    border-color: #000;
  }
  tbody > tr:last-child {
    border-bottom: solid 2px #000;
  }
}
