// begin::Share with PrecintDetailPage
.container-absolute {
  position: absolute;
  top: 100px;bottom: 100px;
  left: 0;right: 0;
  height: calc(100vh - 200px); /* header 100px; footer 100px */
  // font-family: 'Roboto', sans-serif;

  .card {
    height: 100%;
    &-body {
      height: 100%;
      overflow: auto;
    }
  }

  .card-header, .card-footer {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    background: #fff;
    border: 0;
  }
  .card-title {
    // font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    position: relative;
    // &.underline-sm {
    //   display: inline-block;
    //   &::before {
    //     content:'';
    //     display: block;
    //     width: 30%;
    //     height: 3px;
    //     background: #000;
    //     position: absolute;
    //     left: 0;
    //     bottom: -18px;
    //   }
    // }
  }

  .btn {
    padding: 14px 16px 12px;
    border-radius: 0;
    border: 1px solid #000;
    box-shadow: none;
    width: 170px;
    &-solid {
      background-color: #000;
      color: #fff;
      &:hover, &:focus {
        background-color: #333;
      }
    }
    &-outline {
      background: #fff;
      &:hover, &:focus {
        background: #f2f2f2;
      }
    }
    &:hover, &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
// end::Share with PrecintDetailPage


// begin::Can use for PrecintDetailPage too
// just relize after done PrecintDetailPage
.container-absolute {
  .card-body {
    font-weight: 300;
    font-size: 14px;
  }
}
// end::Can use for PrecintDetailPage too

.border-1px {
  border: 1px solid #000;
}
