.wrap-gallery-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .gallery-item {
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      .gallery-hotspot {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 30px;
      }
    }
  }
  .slick-dots {
    bottom: 70px;

    li {
      margin: 0 2px;

      button:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }
}

.btn-gallery {
  display: block;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.btn-gallery.prev-slide {
  left: 10vw;
  color: rgba(255, 255, 255, 0.6);
}

.btn-gallery.next-slide {
  right: 10vw;
  color: rgba(255, 255, 255, 0.6);
}
