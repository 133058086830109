//font

/* Graphie */
@import url('https://use.typekit.net/hom6irb.css');

/* Josefin Sans */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&display=swap');
/* Philosopher */
@import url('https://use.typekit.net/xcu1jzs.css');

//Title font
@font-face {
  font-family: 'PP Hatton';
  src: url('./fonts/PPHatton-Medium.eot');
  src: url('./fonts/PPHatton-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/PPHatton-Medium.woff2') format('woff2'),
    url('./fonts/PPHatton-Medium.woff') format('woff'),
    url('./fonts/PPHatton-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//Main font
@font-face {
  font-family: 'Centra No1 TRIAL';
  src: url('./fonts/CentraNo1TRIAL-Book.eot');
  src: url('./fonts/CentraNo1TRIAL-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CentraNo1TRIAL-Book.woff2') format('woff2'),
    url('./fonts/CentraNo1TRIAL-Book.woff') format('woff'),
    url('./fonts/CentraNo1TRIAL-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Centra No1 TRIAL';
  src: url('./fonts/CentraNo1TRIAL-Light.eot');
  src: url('./fonts/CentraNo1TRIAL-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CentraNo1TRIAL-Light.woff2') format('woff2'),
    url('./fonts/CentraNo1TRIAL-Light.woff') format('woff'),
    url('./fonts/CentraNo1TRIAL-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Centra No1 TRIAL';
  src: url('./fonts/CentraNo1TRIAL-Medium.eot');
  src: url('./fonts/CentraNo1TRIAL-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/CentraNo1TRIAL-Medium.woff2') format('woff2'),
    url('./fonts/CentraNo1TRIAL-Medium.woff') format('woff'),
    url('./fonts/CentraNo1TRIAL-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@import './common';

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.css";

$utilities: map-get-multiple($utilities,
  ('float',
    'text-transform',
    'display',
    'justify-content',
    'flex-direction',
    'text-align',
    'position',
    'height',
    'padding-y'
  ));
@import '../node_modules/bootstrap/scss/utilities/api';

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.list-group {
  &-item {
    opacity: $list-group-item-color-scale;
    cursor: pointer;

    &.active {
      font-weight: 500;
      opacity: 1;
    }

    &:hover {
      color: pointer;
    }

    font-weight: 100;
    font-size: 18px;
  }

  &-heading {
    text-transform: uppercase;
    font-weight: 400;
    opacity: 0.875;
  }
}

.d-flex-column {
  @extend .d-flex;
  @extend .flex-column;

  &>* {
    flex-shrink: 0;
  }
}

.title,
.f-title,
.card-title {
  font-weight: bold;
  text-transform: uppercase;
}

.btn-text {
  color: #000000;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #000000;
  }
}

.side-card {
  height: calc(100vh - 200px);
  border: none;
  border-radius: 0;
  position: absolute;
  z-index: 100;
  top: 100px;
  width: 300px;
  padding: 20px;
  background: rgba(30, 30, 30, 0.9);

  h2 {
    position: relative;
  }

  h2::before {
    content: '';
    position: absolute;
    left: -1.25rem;
    margin-top: -2px;
    top: 50%;
    width: 25px;
    height: 2px;
    background-color: #ffffff;

    @media screen and (max-width: 1025px) {
      width: 15px;
    }
  }

  .card {
    box-shadow: none;
    border: 0px;
    color: #ffffff;
    background: transparent;
  }

  .card-title {
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: 300;
    font-size: 1.7rem;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }
  }

  .btn-arcodion {
    cursor: pointer;
  }

  .wrap-btn-detail .list-group .list-group-item,
  .card-header,
  .list-group-item {
    border: none;
    padding: 0.5rem 1rem;
    color: #ffffff;
    background-color: transparent;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }

    &.active {
      font-weight: 400;
      opacity: 1;
    }

    &:hover {
      font-weight: 400;
      opacity: 1;
    }
  }

  .list-group-heading {
    opacity: 1;
  }

  .card-body {
    padding: 0;
    height: calc(100% - 52px);
    overflow-y: auto;
    background-color: transparent;

    .acordian {
      background: rgba(30, 30, 30, 0.9);
    }
  }

  &.left-card {
    left: 0;
  }

  &.right-card {
    right: 0;
  }
}

.title-center-bottom {
  position: absolute;
  margin-bottom: 0;
  bottom: 24px;
  left: 50vw;
  transform: translateX(-50%);
  color: #ffffff;
  z-index: 111;
  font-size: 2rem;
}

.invisible {
  opacity: 0;
}

.b-fade-text {
  * {
    animation: fadeTop forwards;
    opacity: 0;

    &:nth-child(1) {
      animation-duration: 1s;
    }

    &:nth-child(2) {
      animation-duration: 1.2s;
    }

    &:nth-child(3) {
      animation-duration: 1.4s;
    }

    &:nth-child(4) {
      animation-duration: 1.6s;
    }

    &:nth-child(5) {
      animation-duration: 1.8s;
    }

    &:nth-child(6) {
      animation-duration: 2s;
    }
  }
}

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.underline {
  &-0 {
    text-decoration: none;
  }
  &-sm {
    display: inline-block;
    position: relative;
    &::before {
      content:'';
      display: block;
      width: 30%;
      height: 3px;
      background: #000;
      position: absolute;
      left: 0;
      bottom: -18px;
    }
  }
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background: $value!important;
  }
}

.wrap-bottom-titles {
  bottom: 30px;
  color: $white;
  left: 33px;
  right: 33px;

  .btn-replay {
    line-height: 15px;
    &.underline-sm {
      &::before {
        width: 75px;
        background: $white;
        bottom: -9px;
        height: 1px;
      }
    }
  }
  .svg-icon {
    border-radius: 50%;
    width: fit-content;
    border: 2px solid #fff;
    width: 45px;
    height: 45px;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    margin-top: -45px;
    svg {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
}

.transparent {
  opacity: 0;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
