.wrap-environment-page {
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 90;
  width: 100vw;
  height: calc(100vh - 200px);
  padding: 40px;

  .wrap-env-info {
    height: 100%;
    padding: 20px;
    border: 1px solid #c1c1c1;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .wrap-site-info {
    height: 60%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    margin-bottom: 24px;
  }

  .wrap-site-description {
    height: calc(40% - 24px);
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
  }
}
