.wrap-floor-plan-gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .floorplan-item {
      width: 100vw;
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
      background-color: #434343;
    }
  }
}

.wrap-list-floor {
  .list-group {
    position: absolute;
    width: 100%;
    bottom: 0;

    .list-group-item {
      text-decoration: underline;
      font-size: 14px;
      color: #000;
      opacity: 1;
      font-weight: 300;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
}

.wrap-zoom-btn {
  position: absolute;
  bottom: 80px;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
  }
}
