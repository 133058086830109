.wrap-right-panel {
  position: absolute;
  top: 100px;
  bottom: 100px;
  right: 0;
  height: calc(100vh - 260px); /* header 100px; footer 100px */
  // font-family: 'Roboto', sans-serif;

  @media only screen and (max-width: 1180px) {
    height: calc(100vh - 260px);
  }
  @media only screen and (max-width: 1366px) {
    height: calc(100vh - 260px);
  }
  z-index: 100;
  width: 400px;

  .card {
    height: 100%;
    &-header {
      flex: 1 0 auto;
    }
    &-body {
      height: 100%;
      overflow: auto;
    }
  }
  .card-body {
    padding: 30px;
  }

  .card-header,
  .card-footer {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    padding-right: 30px;
    padding-left: 30px;
    background: #fff;
    border: 0;
  }
  .card-title {
    font-weight: 700;
    font-size: 25px;
    position: relative;
    &.underline-sm {
      &::before {
        // content: '';
        width: 96px;
        height: 1px;
        // background: #000;
        // position: absolute;
        bottom: -13px;
      }
    }
  }
  .list-group {
    &-title {
      font-weight: 700;
      color: #000;
    }
    &-item {
      &:not(.list-group-title):not(.active):hover {
        background: #f2f2f2;
      }
      &.active {
        color: #fff;
        background: #000;
      }
    }
  }
  th {
    font-weight: 700;
  }
  th,
  td {
    white-space: nowrap;
  }
  tr {
    cursor: pointer;
  }
  tr.active {
    background: #000;
    color: #fff;
    &:hover {
      background: #000;
      color: #fff;
    }
  }

  .btn {
    padding: 16px;
    border-radius: 0;
    border: 1px solid #000;
    box-shadow: none;
    // width: 170px;
    font-size: 14px;
    font-weight: bold;
    &-solid {
      background-color: #000;
      color: #fff;
      &:hover,
      &:focus {
        background-color: #333;
      }
    }
    &-outline {
      background: #fff;
      &:hover,
      &:focus {
        background: #f2f2f2;
      }
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &.above-timeline {
    height: calc(100vh - 260px);
  }
}

.wrap-gallery-modal {
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 100px;
  right: 410px;
  z-index: 100;
  left: 20px;
  height: calc(100vh - 260px);
  .card {
    height: 100%;
    &-header {
      flex: 1 0 auto;
    }
    &-body {
      height: 100%;
      overflow: auto;
    }
  }
  .card-header,
  .card-footer,
  .card-body {
    padding: 2rem 2rem 1.5rem;
    background: #fff;
    border: 0;
  }
  .card-title {
    font-weight: 700;
    font-size: 25px;
    &.underline-sm {
      &::before {
        width: 96px;
        height: 1px;
        bottom: -13px;
      }
    }
  }
  .btn_close_gallery {
    margin-top: -30px;
    margin-right: -20px;
  }

  .btn-gallery.prev-slide {
    left: 10px;
  }
  .btn-gallery.next-slide {
    right: 10px;
  }
  .gallery-item {
    width: 100%;
    height: 57vh;
    @media only screen and (max-height: 880px) {
      height: 53vh;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    .gallery-hotspot {
      position: absolute;
      z-index: 100;
      cursor: pointer;
      width: 30px;
    }
  }
  .slick-dots {
    bottom: -30px;

    li {
      margin: 0 2px;

      button:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #000;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #6dcc9d;
        }
      }
    }
  }
}
