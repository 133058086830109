.wrap-bottom-navigation {
  height: 60px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: #000000;
  background: rgba(255,255,255,0.8);

  div {
    z-index: 1;
  }
  .text-black {
    color: #000000;
  }
  &::after {
    content: '';
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) , transparent);
    height: 170px;
    width: 100%;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .left-group-btn {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    font-family: sans-serif;

    .btn-item {
      padding: 0px 20px;
      display: block;
      cursor: pointer;
      svg {
        width: 24px;
      }
    }
  }

  .right-group-btn {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;

    .btn-item {
      padding: 0px 20px;
      font-weight: bold;
      font-family: sans-serif;
      font-size: 13px;
      cursor: pointer;
      svg {
        width: 24px;
      }
    }
  }
}
